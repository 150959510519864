import { useFeatureFlags } from './useFeatureFlags'

/**
 * Returns a boolean value indicating whether neon price is changing
 *
 * @returns {Boolean} usePriceChangeAlertFlag
 */
const usePriceChangeAlertFlag = () => {
  const result = (useFeatureFlags(['price_change_alert'], false)).price_change_alert
  return result
}

export { usePriceChangeAlertFlag }
