import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

import {
  errors, direction, paymentLabel, basicPlanPaymentCopy,
  standardPlanPaymentCopy, annualPlanPaymentCopy
} from '../constants'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import Payment from '../screens/payment'
import Error from '../screens/error'

/**
 * Hook to redirect to change subscription screens based on url parameters
 */

const useChangeSubscriptionScreen = (changeDirection, modalCloseTarget) => {
  const [screen, setscreen] = useState()
  const location = useLocation()
  const history = useHistory()
  const planDetails = usePlanChangeFlag()

  const products = {
    annual: {
      ...planDetails.annual,
      copy: annualPlanPaymentCopy,
      details: [
        planDetails.annual.staticDetail,
        {
          name: paymentLabel,
          value: `$${planDetails.annual.price}`
        }
      ]
    },
    standard: {
      ...planDetails.standard,
      copy: standardPlanPaymentCopy,
      details: [
        planDetails.standard.staticDetail,
        {
          name: paymentLabel,
          value: `$${planDetails.standard.price}`
        }
      ]
    },
    basic: {
      ...planDetails.basic,
      copy: basicPlanPaymentCopy,
      details: [
        planDetails.basic.staticDetail,
        {
          name: paymentLabel,
          value: `$${planDetails.basic.price}`
        }
      ]
    }
  }

  const {
    plan,
    errorCode,
    orderId
  } = qs.parse(location.search)

  if (
    changeDirection !== direction.upgrade &&
    changeDirection !== direction.downgrade
  ) {
    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        errorCode: errors.modalParemetersInvalid.errorCode
      })
    })
  }

  const closeModalAndRedirect = () => {
    history.push({
      pathname: modalCloseTarget
    })
  }

  const getErrorScreen = () => {
    const error =
      Object.values(errors).find(e => e.errorCode === errorCode) ||
      errors.modalParemetersInvalid
    return <Error error={error} onStartOver={closeModalAndRedirect} />
  }

  const getPaymentScreen = () => {
    const product = Object.values(products).find(p => p.sku === plan)
    return <Payment product={product} changeDirection={changeDirection} />
  }

  // no plan or errorCode=001 => shows error screen
  // plan={sku} => shows payment screen
  // orderId=123 => redirect to /{modalCloseTarget}
  useEffect(() => {
    let screenComponent = null
    if (!plan || errorCode) {
      screenComponent = getErrorScreen()
      setscreen(screenComponent)
    } else if (orderId) {
      closeModalAndRedirect()
    }
  }, [plan, errorCode, orderId])

  return screen || getPaymentScreen()
}

export default useChangeSubscriptionScreen
